import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { handleLogout } from "../../utils/auth/AuthActions";
import { ICON_TYPE } from "../../utils/enums/globalEnums";
import { getIcon } from "../../utils/iconHelper";
import { CompanyContext } from "../../utils/contexts/globalContexts";

type Props = { handleClickChangeCompany: (() => void) | null };

export default function BasicPopover({ handleClickChangeCompany }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { companies } = React.useContext(CompanyContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        sx={{ height: "50px" }}
        variant="contained"
        onClick={handleClick}
      >
        {getIcon(ICON_TYPE.EXIT)}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {handleClickChangeCompany !== null && (
          <Button
            variant="contained"
            disabled={companies?.length === 0}
            onClick={handleClickChangeCompany}
            sx={{
              p: 1,
              width: "100%",
              backgroundColor: "rgba(61, 121, 189, 0.5)",
              fontWeight: 700,
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(61, 121, 189, 0.7)",
                color: "#ffffff",
              },
            }}
          >
            Vissza a cégekhez
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleLogout}
          sx={{
            p: 1,
            width: "100%",
            backgroundColor: "rgba(244, 38, 38, 0.5)",
            fontWeight: 700,
            color: "#fff",
            "&:hover": {
              backgroundColor: "rgba(244, 38, 38, 0.7)",
              color: "#ffffff",
            },
          }}
        >
          Kijelentkezés
        </Button>
      </Popover>
    </div>
  );
}
