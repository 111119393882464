import { Box, Grid } from "@mui/material";
import StyledInput, { INPUT_TYPE } from "../input/StyledInput";
import { Fragment, useContext, useState } from "react";
import { showSuccessMessage } from "../../utils/reducers/rootReducer";
import {
  SIZE,
  COLOR,
  MODAL_TYPE,
  SecondModal,
} from "../../utils/enums/globalEnums";
import StyledButton from "../button/StyledButton";
import {
  RefreshContext,
  SecondModalContext,
} from "../../utils/contexts/globalContexts";
import { Role } from "../../InvitationApi";
import { ApiError, User, UserService } from "../../UserManagerApi";
import { store } from "../../providers";
import { getErrorMessage } from "../../utils/helper-functions/HelperFunctions";

type Props = { user?: User };

const EditUserFields = ({ user }: Props) => {
  const [userToEdit, setUserToEdit] = useState(user);
  const [isLoading, setIsLoading] = useState(false);
  const { setSecondModalProps } = useContext(SecondModalContext);
  const { setRefresh } = useContext(RefreshContext);

  const handleChange = (e: any) => {
    setUserToEdit((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const editUser = async () => {
    setIsLoading(true);
    await UserService.patchUsers(userToEdit)
      .then((res) => {
        store.dispatch(
          showSuccessMessage([
            "Felhasználó szerkesztése",
            `${userToEdit?.firstName} ${userToEdit?.lastName} szerkesztése sikeres`,
            () => setRefresh(true),
          ])
        );
      })
      .catch((e: ApiError) => {
        setSecondModalProps((prev: SecondModal) => ({
          ...prev,
          open: true,
          type: MODAL_TYPE.ERROR,
          title: "Hiba",
          description: getErrorMessage(e.status),
        }));
      });
  };

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mt: "10px" }} minWidth={"500px"}>
        <Grid item xs={2} alignSelf="center">
          Szerepkör
        </Grid>
        <Grid item xs={10}>
          <StyledInput
            type={INPUT_TYPE.SELECT}
            options={[Role.ADMIN, Role.ACCOUNTANT, Role.USER]}
            changed={handleChange}
            value={userToEdit?.role ?? ""}
            name={"role"}
          />
        </Grid>
      </Grid>
      <Box position="absolute" bottom="0px" mb={2} width={"calc(100% - 64px)"}>
        <Grid container justifyContent={"flex-end"}>
          <Grid item>
            <StyledButton
              // loading={isLoading}
              size={SIZE.SMALL}
              text={"Szerkesztés"}
              color={COLOR.DATAPOINT}
              onClick={editUser}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default EditUserFields;
