import "./App.scss";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./utils/reducers/rootReducer";
import { initialSecondModal } from "./utils/enums/globalEnums";
import {
  CompanyContext,
  CompanyProps,
  DeviceContext,
  DeviceProps,
  RefreshContext,
  RefreshProps,
  SecondModalContext,
  SecondModalProps,
  SelectedCompanyContext,
  SelectedCompanyProps,
  UsersContext,
  UsersProps,
} from "./utils/contexts/globalContexts";
import { useState } from "react";
import StyledModal from "./components/modal/StyledModal";
import _ from "lodash";
import { B2CCompanyData, User } from "./UserManagerApi";
import { acquireToken } from "./utils/auth/AuthActions";
import { useMsal } from "@azure/msal-react";
import Modal from "./components/modal/Modal";
import { OpenAPI as UserManagerOpenAPI } from "./UserManagerApi/core/OpenAPI";
import { OpenAPI as InvitationOpenAPI } from "./InvitationApi/core/OpenAPI";

export const store = createStore(rootReducer);

export function Providers({ children }: any) {
  UserManagerOpenAPI.TOKEN = async () => (await acquireToken()) as any;
  UserManagerOpenAPI.BASE = process.env.REACT_APP_API_URL as string;

  InvitationOpenAPI.TOKEN = async () => (await acquireToken()) as any;
  InvitationOpenAPI.BASE = process.env.REACT_APP_INVITATION_API_URL as string;

  const { accounts } = useMsal();

  const [isMobile, setIsMobile] = useState(false);
  const [isMobileLandscape, setIsMobileLandscape] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({} as B2CCompanyData);
  const [companies, setCompanies] = useState([] as B2CCompanyData[]);
  const [users, setUsers] = useState([] as User[]);
  const [secondModalProps, setSecondModalProps] = useState(
    _.cloneDeep(initialSecondModal)
  );

  const handleCloseSecondModal = () =>
    setSecondModalProps(_.cloneDeep(initialSecondModal));

  return (
    <Provider store={store}>
      <div className="App">
        <DeviceContext.Provider
          value={
            {
              isMobile,
              setIsMobile,
              isMobileLandscape,
              setIsMobileLandscape,
            } as DeviceProps
          }
        >
          <RefreshContext.Provider
            value={{ refresh, setRefresh } as RefreshProps}
          >
            <UsersContext.Provider value={{ users, setUsers } as UsersProps}>
              <SelectedCompanyContext.Provider
                value={
                  {
                    selectedCompany,
                    setSelectedCompany,
                  } as SelectedCompanyProps
                }
              >
                <CompanyContext.Provider
                  value={{ companies, setCompanies } as CompanyProps}
                >
                  <SecondModalContext.Provider
                    value={
                      {
                        secondModalProps,
                        setSecondModalProps,
                      } as SecondModalProps
                    }
                  >
                    <Modal />
                    <StyledModal
                      open={secondModalProps.open}
                      handleClose={handleCloseSecondModal}
                      title={secondModalProps.title}
                      type={secondModalProps.type}
                      description={secondModalProps.description}
                    />
                    {children}
                  </SecondModalContext.Provider>
                </CompanyContext.Provider>
              </SelectedCompanyContext.Provider>
            </UsersContext.Provider>
          </RefreshContext.Provider>
        </DeviceContext.Provider>
      </div>
    </Provider>
  );
}
