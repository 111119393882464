import { Box, Grid } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { StyledTab } from "./components/tabs/styles/StyledTab";
import { StyledTabs } from "./components/tabs/styles/StyledTabs";
import { TabPanel } from "./components/tabs/TabPanel";
import UserTab from "./components/tabs/UserTab";
import {
  COLOR,
  ICON_TYPE,
  LOCAL_STORAGE_KEY,
  TAB,
} from "./utils/enums/globalEnums";
import StyledButton from "./components/button/StyledButton";
import { store } from "./providers";
import i18n from "./utils/i18n";
import {
  showErrorMessage,
  showSuccessMessage,
  showUserInvite,
} from "./utils/reducers/rootReducer";
import {
  CompanyContext,
  RefreshContext,
  SelectedCompanyContext,
  UsersContext,
} from "./utils/contexts/globalContexts";
import {
  ApiError,
  B2CCompanyData,
  Role,
  User,
  UserService,
} from "./UserManagerApi";
import HeaderLayout from "./components/layout/header/HeaderLayout";
import Header from "./components/layout/header/Header";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "./utils/helper-functions/HelperFunctions";
import { acquireToken } from "./utils/auth/AuthActions";
import BasicPopover from "./components/popover/BasicPopover";
import { useMsal } from "@azure/msal-react";
import { OpenAPI as UserManagerOpenAPI } from "./UserManagerApi/core/OpenAPI";
import { OpenAPI as InvitationOpenAPI } from "./InvitationApi/core/OpenAPI";
import { cutLongString } from "./utils/helperFunctions";

export default function Users() {
  const navigate = useNavigate();
  const [tab, setTab] = useState(TAB.USERS);
  const [isLoading, setIsLoading] = useState(false);
  const { users, setUsers } = useContext(UsersContext);
  const { refresh, setRefresh } = useContext(RefreshContext);
  const { companies, setCompanies } = useContext(CompanyContext);
  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );
  const { accounts } = useMsal();

  useEffect(() => {
    setApiHeaders();
    const dbGuid = localStorage.getItem(LOCAL_STORAGE_KEY.DB_GUID);
    if (!dbGuid) {
      navigate("/selector");
    } else {
      if (!selectedCompany?.guid) {
        changeSelectedCompany(
          localStorage.getItem(LOCAL_STORAGE_KEY.DB_GUID) ?? ""
        );
      }
      getUsers().then(() => setIsLoading(false));
    }
    return () => {
      setIsLoading(true);
      setTab(TAB.USERS);
      setUsers([] as User[]);
    };
  }, []);

  useEffect(() => {
    if (refresh) {
      getUsers();
      setRefresh(false);
    }
  }, [refresh]);

  const getUsers = async () => {
    await UserService.getUsers()
      .then((res) => {
        if (res !== undefined && res[0] !== undefined)
          setUsers(res.filter((u) => !u.deleted));
      })
      .catch((e: ApiError) => {
        store.dispatch(showErrorMessage(["Hiba", getErrorMessage(e.status)]));
      });
  };

  const getCompanyList = async () => {
    let returnval = null;
    await UserService.getUsersCompanyList()
      .then(async (res) => {
        if (res !== null || res[0] !== null) {
          setCompanies(res);
          returnval = res;
        } else {
          store.dispatch(showErrorMessage(["", "Nem érhetőek el adatok."]));
        }
      })
      .catch((e: ApiError) => {
        store.dispatch(
          showErrorMessage(["Hiba történt", getErrorMessage(e.status)])
        );
      });
    return returnval;
  };

  const setApiHeaders = () => {
    UserManagerOpenAPI.TOKEN = async () => (await acquireToken()) as any;
    UserManagerOpenAPI.HEADERS = {
      dbguid: localStorage.getItem(LOCAL_STORAGE_KEY.DB_GUID) as string,
    };

    InvitationOpenAPI.TOKEN = async () => (await acquireToken()) as any;

    InvitationOpenAPI.HEADERS = {
      dbguid: localStorage.getItem(LOCAL_STORAGE_KEY.DB_GUID) as string,
    };
  };

  const changeSelectedCompany = async (dbId = "") => {
    if (dbId !== "") {
      let complist: B2CCompanyData[] | null;
      if (companies?.length === 0) {
        complist = await getCompanyList();
      } else complist = companies;
      if (complist && complist?.length > 0) {
        const filtered = complist?.filter((dbinfo) => dbinfo.guid === dbId);
        if (filtered[0] !== undefined) {
          setSelectedCompany(filtered[0]);
          localStorage.setItem(
            LOCAL_STORAGE_KEY.DB_GUID,
            filtered[0]?.guid ?? ""
          );
          UserManagerOpenAPI.HEADERS = { dbguid: filtered[0]?.guid ?? "" };
          InvitationOpenAPI.HEADERS = { dbguid: filtered[0]?.guid ?? "" };

          setRefresh(true);
        }
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TAB) =>
    setTab(newValue);

  const handleUserInviteClick = () => {
    store.dispatch(
      showSuccessMessage([
        "Felhasználó meghívása",
        "A felhasználó meghívása sikeres volt.",
      ])
    );
  };

  const clickInviteUser = () => {
    const currentUser = users.filter(
      (user) => user.objectId === accounts[0]?.localAccountId
    );
    if (currentUser?.length === 1 && currentUser[0]?.role !== Role.ADMIN) {
      store.dispatch(
        showErrorMessage([
          "Hiba",
          "Meghívó kiküldése csak Admin jogosultsággal lehetséges.",
        ])
      );
      return;
    }
    store.dispatch(
      showUserInvite([
        i18n("modal.invite.title"),
        i18n("modal.invite.description"),
        handleUserInviteClick,
      ])
    );
  };

  const handleClickChangeCompany = () => {
    setSelectedCompany({} as B2CCompanyData);
    localStorage.removeItem(LOCAL_STORAGE_KEY.DB_GUID);
    navigate("/selector");
  };

  return (
    <Fragment>
      <Header>
        <HeaderLayout
          rightContent={
            <Grid container mr={"15px"} spacing={1}>
              <Grid item xs={true}>
                <div
                  style={{
                    fontSize: "37px",
                    marginRight: "15px",
                    textOverflow: "ellipsis",
                    wordBreak: "break-all",
                  }}
                >
                  {cutLongString(selectedCompany?.companyName ?? "", 50)}
                </div>
                <div>{accounts?.length > 0 && accounts[0].username}</div>
              </Grid>

              <Grid item>
                <BasicPopover
                  handleClickChangeCompany={handleClickChangeCompany}
                />
              </Grid>
            </Grid>
          }
        />
      </Header>
      <Box
        sx={{
          p: "0px 25px 0px 25px",
          height: "calc(100vh - 85px)",
          mt: "90px",
        }}
      >
        <StyledTabs value={tab} onChange={handleChange}>
          <StyledTab
            value={TAB.USERS}
            label={`${TAB.USERS} (${users?.length ?? 0})`}
            key={TAB.USERS}
          />
          <StyledButton
            text={i18n("buttons.sendInvite")}
            needContainer
            onClick={clickInviteUser}
            color={COLOR.GREEN}
          />
          <StyledButton
            text={""}
            // needContainer
            icon={ICON_TYPE.REFRESH}
            onClick={getUsers}
            color={COLOR.BLUE}
          />
        </StyledTabs>
        <TabPanel value={tab} index={TAB.USERS}>
          <UserTab users={users} isLoading={isLoading} />
        </TabPanel>
        {/* <TabPanel value={tab} index={TAB.PERMISSIONS}>
        <PermissionTab />
      </TabPanel> */}
      </Box>
    </Fragment>
  );
}
