/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResultDto } from '../models/ResultDto';
import type { Role } from '../models/Role';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvitationService {

    /**
     * @param mailTo 
     * @param role 
     * @param lastName 
     * @param firstName 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postInvitation(
mailTo?: string,
role?: Role,
lastName?: string,
firstName?: string,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Invitation',
            query: {
                'mailTo': mailTo,
                'role': role,
                'lastName': lastName,
                'firstName': firstName,
            },
        });
    }

    /**
     * @param state 
     * @param idToken 
     * @param accessToken 
     * @param error 
     * @param errorDescription 
     * @returns any Success
     * @throws ApiError
     */
    public static getInvitationCallback(
state?: string,
idToken?: string,
accessToken?: string,
error?: string,
errorDescription?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Invitation/callback',
            query: {
                'state': state,
                'id_token': idToken,
                'access_token': accessToken,
                'error': error,
                'error_description': errorDescription,
            },
        });
    }

}
