import { Fragment, useContext, useEffect } from "react";
import CompanySelector from "./components/company-selector/CompanySelector";
import { store } from "./providers";
import { UserService, ApiError, B2CCompanyData } from "./UserManagerApi";
import {
  COLOR,
  INTERNAL_ROUTE,
  LOCAL_STORAGE_KEY,
} from "./utils/enums/globalEnums";
import { showErrorMessage } from "./utils/reducers/rootReducer";
import {
  CompanyContext,
  SelectedCompanyContext,
} from "./utils/contexts/globalContexts";
import StyledButton from "./components/button/StyledButton";
import { useNavigate } from "react-router-dom";
import Row from "./components/layout/Row";
import { getErrorMessage } from "./utils/helper-functions/HelperFunctions";
import { acquireToken } from "./utils/auth/AuthActions";
import { OpenAPI as UserManagerOpenAPI } from "./UserManagerApi/core/OpenAPI";
import { OpenAPI as InvitationOpenAPI } from "./InvitationApi/core/OpenAPI";
import { Grid } from "@mui/material";
import Header from "./components/layout/header/Header";
import HeaderLayout from "./components/layout/header/HeaderLayout";
import BasicPopover from "./components/popover/BasicPopover";
import { useMsal } from "@azure/msal-react";
import { cutLongString } from "./utils/helperFunctions";

export default function Selector() {
  const navigate = useNavigate();
  const { companies, setCompanies } = useContext(CompanyContext);
  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );
  const { accounts } = useMsal();

  useEffect(() => {
    setApiHeaders();
    const dbGuid = localStorage.getItem(LOCAL_STORAGE_KEY.DB_GUID);
    if (dbGuid) {
      navigate(INTERNAL_ROUTE.USERS);
    } else {
      if (companies?.length === 0) getCompanyList();
    }
  }, []);

  const setApiHeaders = () => {
    UserManagerOpenAPI.TOKEN = async () => (await acquireToken()) as any;

    InvitationOpenAPI.TOKEN = async () => (await acquireToken()) as any;
  };

  const getCompanyList = async () => {
    await UserService.getUsersCompanyList()
      .then(async (res) => {
        if (res !== null || res[0] !== null) {
          setCompanies(res);
          if (res?.length === 1) {
            await changeSelectedCompany(res[0]).then(() => {
              navigate(INTERNAL_ROUTE.USERS);
            });
          }
        } else
          store.dispatch(showErrorMessage(["", "Nem érhetőek el adatok."]));
      })
      .catch((e: ApiError) =>
        store.dispatch(
          showErrorMessage(["Hiba történt", getErrorMessage(e.status)])
        )
      );
  };

  const changeSelectedCompanyByGuid = async (dbId: string) => {
    const filtered = companies?.filter((dbinfo) => dbinfo.guid === dbId);
    if (filtered[0] !== undefined) changeSelectedCompany(filtered[0]);
  };

  const changeSelectedCompany = async (comp: B2CCompanyData) => {
    await setSelectedCompany(comp);
    localStorage.setItem(LOCAL_STORAGE_KEY.DB_GUID, comp?.guid ?? "");
    UserManagerOpenAPI.HEADERS = { dbguid: comp?.guid ?? "" };
    InvitationOpenAPI.HEADERS = { dbguid: comp?.guid ?? "" };
  };

  return (
    <Fragment>
      <Header>
        <HeaderLayout
          rightContent={
            <Grid container mr={"15px"} spacing={1}>
              <Grid item xs={true}>
                <div
                  style={{
                    fontSize: "37px",
                    marginRight: "15px",
                    textOverflow: "ellipsis",
                    wordBreak: "break-all",
                  }}
                >
                  {cutLongString(selectedCompany?.companyName ?? "", 50)}
                </div>
                <div>{accounts?.length > 0 && accounts[0].username}</div>
              </Grid>

              <Grid item>
                <BasicPopover handleClickChangeCompany={null} />
              </Grid>
            </Grid>
          }
        />
      </Header>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Row>
          <div style={{ fontSize: "3rem", paddingBottom: "70px" }}>
            {"Kulcs-Portál"}
          </div>
        </Row>
        <div style={{ height: "150px" }}>
          <CompanySelector
            text="Válasszon céget"
            companies={companies}
            changeSelectedCompany={changeSelectedCompanyByGuid}
          />
        </div>
        <div>
          <StyledButton
            disabled={!selectedCompany?.guid}
            color={COLOR.DATAPOINT}
            text="Tovább a felhasználókra"
            onClick={() => {
              navigate(INTERNAL_ROUTE.USERS);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}
