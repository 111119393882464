import { Box, Grid } from "@mui/material";
import StyledInput, { INPUT_TYPE } from "../input/StyledInput";
import { Fragment, useContext, useState } from "react";
import { showSuccessMessage } from "../../utils/reducers/rootReducer";
import {
  SIZE,
  COLOR,
  SecondModal,
  MODAL_TYPE,
} from "../../utils/enums/globalEnums";
import i18n from "../../utils/i18n";
import StyledButton from "../button/StyledButton";
import {
  RefreshContext,
  SecondModalContext,
  UsersContext,
} from "../../utils/contexts/globalContexts";
import { InvitationService, ResultDto, Role } from "../../InvitationApi";
import { ApiError, User } from "../../UserManagerApi";
import { store } from "../../providers";

type Props = {};

const InviteUserFields = ({}: Props) => {
  const [user, setUser] = useState({} as User);
  const [isLoading, setIsLoading] = useState(false);
  const { setSecondModalProps } = useContext(SecondModalContext);
  const { setRefresh } = useContext(RefreshContext);
  const { users } = useContext(UsersContext);

  const handleChange = (e: any) => {
    setUser((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const sendInvite = async () => {
    if (users?.map((u) => u.email)?.includes(user.email)) {
      console.log(1);
      setSecondModalProps((prev: SecondModal) => ({
        ...prev,
        open: true,
        type: MODAL_TYPE.ERROR,
        title: "Hiba",
        description:
          "A megadott e-mail cím már szerepel a felhasználók listájában!",
      }));
      return;
    }
    var error = "";
    if (user.email && user.role) {
      setIsLoading(true);
      await InvitationService.postInvitation(
        user.email,
        user.role,
        user.lastName ?? "",
        user.firstName ?? ""
      )
        .then((res) => {
          let result = res as ResultDto;
          console.log(2);
          console.log(res);
          store.dispatch(
            result.isSuccess
              ? showSuccessMessage([
                  "Meghívó küldése",
                  `A meghívó kiküldése ${user.email} e-mail címre sikeresen megtörtént.
                    Kérjük ellenőrizze a meghívott az e-mail fiókját!`,
                  () => setRefresh(true),
                ])
              : (error = res.errorMsg ?? "")
          );
        })
        .catch((e: ApiError) => {
          error = e.stack ?? "Hiba történt az adatok betöltése során.";
        });
    } else {
      console.log(3);
      setSecondModalProps((prev: SecondModal) => ({
        ...prev,
        open: true,
        type: MODAL_TYPE.ERROR,
        title: "Hiba",
        description: "Az email cím és szerepkör mezők kitöltése kötelező!",
      }));
    }
    if (error !== "") {
      console.log(4);
      setSecondModalProps((prev: SecondModal) => ({
        ...prev,
        open: true,
        type: MODAL_TYPE.ERROR,
        title: "Hiba",
        description: error,
      }));
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mt: "10px" }} minWidth={"500px"}>
        <Grid item xs={2} alignSelf="center">
          Vezetéknév
        </Grid>
        <Grid item xs={4}>
          <StyledInput
            type={INPUT_TYPE.TEXT}
            changed={handleChange}
            value={user?.lastName ?? ""}
            name={"lastName"}
          />
        </Grid>
        <Grid item xs={2} alignSelf="center">
          Keresztnév
        </Grid>
        <Grid item xs={4}>
          <StyledInput
            type={INPUT_TYPE.TEXT}
            changed={handleChange}
            value={user?.firstName ?? ""}
            name={"firstName"}
          />
        </Grid>
        <Grid item xs={2} alignSelf="center">
          Email cím
        </Grid>
        <Grid item xs={4}>
          <StyledInput
            type={INPUT_TYPE.TEXT}
            changed={handleChange}
            value={user?.email ?? ""}
            name={"email"}
          />
        </Grid>
        <Grid item xs={2} alignSelf="center">
          Szerepkör
        </Grid>
        <Grid item xs={4}>
          <StyledInput
            type={INPUT_TYPE.SELECT}
            options={[Role.ADMIN, Role.ACCOUNTANT, Role.USER]}
            changed={handleChange}
            value={user?.role ?? ""}
            name={"role"}
          />
        </Grid>
      </Grid>
      <Box position="absolute" bottom="0px" mb={2} width={"calc(100% - 64px)"}>
        <Grid container justifyContent={"flex-end"}>
          <Grid item>
            <StyledButton
              // loading={isLoading}
              size={SIZE.SMALL}
              text={i18n("buttons.sendInvite")}
              color={COLOR.DATAPOINT}
              onClick={sendInvite}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default InviteUserFields;
