import { User } from "../../UserManagerApi";

export enum INTERNAL_ROUTE {
  START = "/",
  USERS = "/users",
  COMPANYSELECTOR = "/selector",
}

export enum BACKEND_ROUTE {}

export enum SESSION_KEY {
  LANGUAGE = "language",
}

export enum LOCAL_STORAGE_KEY {
  MS_AUTH_TOKEN = "msAuthToken",
  ACCESS_TOKEN = "token",
  DB_GUID = "dbguid",
  PROGRAM = "program",
}

export const enum TAB {
  USERS = "Felhasználók",
  PERMISSIONS = "Jogosultságok",
}

export const enum STATUS {
  ACTIVE = "Aktív",
  INACTIVE = "Inaktív",
  INVITED = "Meghívva",
}

export enum SIZE {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum ICON_TYPE {
  PLUS,
  PEN,
  RETRY,
  TRASH,
  OTHER,
  KULCSUZLET,
  DATAPOINT,
  DOCUSTORE,
  REFRESH,
  EXIT,
}

export enum COLOR {
  DATAPOINT = "#007aff",
  DATAPOINT_DARK = "#003A75",
  BLUE = "#3D79BD",
  MEDIUM_BLUE = "#ACBBCD",
  LIGHT_BLUE = "#E6F4FD",
  GREEN = "#619D32",
  YELLOW = "#E09320",
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  GRAY = "#858585",
  LIGHTGRAY = "#99999A",
  PURPLE = "#8C2CFF",
  RED = "#EA4335",
}

export enum PROGRAMTYPE {
  DATAPOINT,
  KULCSUZLET,
  DOCUSTORE,
}

export enum MODAL_TYPE {
  NONE,
  SUCCESS,
  ERROR,
  CONFIRMATION,
  INVITE,
  LOADING,
  DELETE_USER,
  EDIT_USER,
}

export type ModalModel = {
  text: string;
  isShown: boolean;
  modalType: MODAL_TYPE;
  handleConfirmedAction?: () => void;
  itemId?: string;
  description?: string;
  item?: User;
};

export const initialSecondModal = {
  title: "",
  open: false,
  type: MODAL_TYPE.NONE,
  description: "",
} as SecondModal;

export type SecondModal = {
  open: boolean;
  handleClose: () => void;
  title: string | string[];
  type: MODAL_TYPE;
  description?: string;
};
