/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthValidationRequest } from '../models/AuthValidationRequest';
import type { B2CCompanyData } from '../models/B2CCompanyData';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @returns User Success
     * @throws ApiError
     */
    public static getUsers(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
        });
    }

    /**
     * @param requestBody 
     * @returns User Success
     * @throws ApiError
     */
    public static postUsers(
requestBody?: User,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static patchUsers(
requestBody?: User,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyName 
     * @param dbGuid 
     * @returns any Success
     * @throws ApiError
     */
    public static postUsersNewConnection(
companyName?: string,
dbGuid?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/NewConnection',
            query: {
                'companyName': companyName,
                'dbGuid': dbGuid,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUsers(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param companyName 
     * @returns any Success
     * @throws ApiError
     */
    public static patchUsersCompanyName(
companyName?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/users/CompanyName',
            query: {
                'companyName': companyName,
            },
        });
    }

    /**
     * @returns B2CCompanyData Success
     * @throws ApiError
     */
    public static getUsersCompanyList(): CancelablePromise<Array<B2CCompanyData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/CompanyList',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUsersDeleteUsersByDbGuid(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/DeleteUsersByDbGuid',
        });
    }

    /**
     * @param invitationKey 
     * @returns User Success
     * @throws ApiError
     */
    public static getUsers1(
invitationKey: string,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{invitationKey}',
            path: {
                'invitationKey': invitationKey,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns User Success
     * @throws ApiError
     */
    public static postUsersActivateUser(
requestBody?: User,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/activateUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postUsersB2CRole(
requestBody?: AuthValidationRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/B2C/role',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
